// Bootstrap Framework’
@import "../../../node_modules/bootstrap/scss/bootstrap";

// AOS 3.0.0-beta.6 plugin CSS (Animations)
@import "../../../node_modules/aos/dist/aos.css";

// Boostrap icons
@import "../../../node_modules/bootstrap-icons/font/bootstrap-icons.css";

// Css custom
@import './theme/abstracts';
@import './theme/base';
@import './theme/gutenberg';
@import './theme/components';
@import './theme/pages';